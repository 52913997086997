const CI_COMMIT_REF_NAME = "trunk";
const CI_COMMIT_SHA = "52baf16b945cf426b63ecf2354d9863510a79ce0";
const CI_COMMIT_SHORT_SHA = "52baf16b";
const INTEGRATES_BUCKET_NAME = "integrates.front.production.fluidattacks.com";
const INTEGRATES_DEPLOYMENT_DATE = "2024-09-17T18:19:36Z";

export {
  CI_COMMIT_REF_NAME,
  CI_COMMIT_SHA,
  CI_COMMIT_SHORT_SHA,
  INTEGRATES_BUCKET_NAME,
  INTEGRATES_DEPLOYMENT_DATE,
};
