import { placeholder } from "@cloudinary/react";
import React from "react";

import { ClientsContainer, SlideShow } from "./styles";

import { CloudImage } from "components/cloud-image";
import { Container } from "components/container";

const Slider: React.FC = (): JSX.Element => {
  const clients = [
    "bancolombia",
    "banco-general",
    "interbank",
    "btgpactual",
    "sura",
    "copa-airlines",
    "banistmo",
    "banesco",
    "bantrab",
    "banco-pichincha",
    "banco-falabella",
    "avianca",
    "tigo",
    "oxxo",
    "banco-industrial",
    "global-bank",
    "proteccion",
    "redeban",
    "telered",
    "ach",
    "yappy",
    "payvalida",
    "zinli",
    "qiip",
    "la-haus",
    "finaktiva",
    "modyo",
    "nequi",
    "colmedica",
    "b89",
    "grupo-exito",
    "soy-yo",
    "puntos-colombia",
    "wompi",
    "banco-azul",
    "serfinanza",
    "banco-bisa",
    "banco-sol",
    "banco-caja-social",
    "bold",
    "tower-tech",
    "travel",
    "gesa",
    "spyderbat",
    "nutresa",
    "coordinadora",
    "rapicredit",
  ];

  const slider = [0, 1].map((re): JSX.Element[] =>
    clients.map((client): JSX.Element => {
      const key = `${client}-${re}`;

      return (
        <Container
          display={"flex"}
          justify={"center"}
          key={key}
          width={"140px"}
          wrap={"wrap"}
        >
          <CloudImage
            alt={key}
            height={"80px"}
            key={key}
            plugins={[placeholder()]}
            publicId={`airs/home/ClientSection/updateLogos/${client}-logo`}
            width={"140px"}
          />
        </Container>
      );
    }),
  );

  return (
    <ClientsContainer>
      <SlideShow>{slider}</SlideShow>
    </ClientsContainer>
  );
};

export { Slider };
