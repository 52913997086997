import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";

import { Slider } from "./slider";
import {
  AdvertisementContent,
  AdvertisementWrapper,
  FormWrapper,
  SignupGrid,
  StyledLink,
  StyledList,
} from "./styles";

import { CloudImage } from "components/cloud-image";
import { Container } from "components/container";
import { Divider } from "components/divider";
import { Icon } from "components/icon";
import { Modal, ModalConfirm } from "components/modal";
import { Heading, Text } from "components/typography";
import { useCloudinaryImage } from "hooks/use-cloudinary-image";
import { useModal } from "hooks/use-modal";
import { LoginProviders } from "pages/login/big-screen/provider-form/login-providers";

const BigScreenSignup: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const backgroundImage = useCloudinaryImage({
    publicId: "integrates/login/bannerLogin",
  });
  const theme = useTheme();
  const navigate = useNavigate();
  const modalRef = useModal("signup-error-modal");
  const listItems = [
    "Continuous security testing through AppSec tools (SAST, DAST, SCA and CSPM)",
    "A single pane of glass",
    "Help for vulnerability remediation",
  ];

  useEffect((): void => {
    const searchParams = new URLSearchParams(location.search);
    const hasInvalidEmail = Boolean(searchParams.get("error"));
    if (hasInvalidEmail) {
      modalRef.open();
    }
  }, [modalRef]);

  const handleClose = useCallback((): void => {
    modalRef.close();
    navigate(location.pathname, { replace: true });
  }, [modalRef, navigate]);

  return (
    <SignupGrid>
      <FormWrapper>
        <Container
          display={"flex"}
          flexDirection={"column"}
          gap={3}
          maxWidth={"388px"}
        >
          <Container
            alignItems={"center"}
            display={"flex"}
            flexDirection={"column"}
          >
            <CloudImage
              alt={"Fluid Attacks Logo"}
              publicId={"integrates/login/loginLogoExtended"}
              width={"220px"}
            />
            <Text
              color={theme.palette.gray[400]}
              mt={2.25}
              size={"md"}
              textAlign={"center"}
            >
              {"FREE TRIAL"}
            </Text>
            <Heading
              color={theme.palette.gray[800]}
              mt={0.5}
              size={"md"}
              spacing={2.5}
              textAlign={"center"}
              weight={"bold"}
            >
              {"Create your account with "}
              {"your work email"}
            </Heading>
            <Text
              color={theme.palette.gray[500]}
              mt={0.25}
              size={"lg"}
              spacing={1.75}
              textAlign={"center"}
            >
              {"Already have an account?  "}
              <StyledLink color={theme.palette.primary[500]} to={"/"}>
                {"Log in"}
              </StyledLink>
            </Text>
          </Container>
          <LoginProviders section={"Signup"} />

          <Text
            color={theme.palette.gray[400]}
            size={"sm"}
            textAlign={"center"}
          >
            <StyledLink to={"https://fluidattacks.com/terms-use/"}>
              {t("login.footer.term1")}
            </StyledLink>
            {t("login.footer.term2")}
            <StyledLink to={"https://fluidattacks.com/privacy/"}>
              {t("login.footer.term3")}
            </StyledLink>
            <br />
            {t("login.footer.copyright")}
          </Text>
        </Container>
      </FormWrapper>
      <AdvertisementWrapper url={backgroundImage.toURL()}>
        <AdvertisementContent>
          <Heading
            color={"white"}
            size={"md"}
            spacing={2.5}
            weight={"bold"}
            wordBreak={"break-word"}
          >
            {"Develop and deploy secure software without delays."}
          </Heading>
          <Text
            color={"white"}
            mb={2.25}
            mt={0.5}
            size={"lg"}
            spacing={1.75}
            weight={"bold"}
            wordBreak={"break-word"}
          >
            {
              "Try Continuous Hacking free for 21 days. No credit card required."
            }
          </Text>
          <StyledList>
            {listItems.map((item, index): JSX.Element => {
              const key = `item-${index}`;

              return (
                <li key={key}>
                  <Icon
                    color={theme.palette.gray[100]}
                    icon={"circle-check"}
                    size={"lg"}
                  />
                  <Text
                    color={theme.palette.gray[100]}
                    size={"md"}
                    spacing={1.5}
                  >
                    {item}
                  </Text>
                </li>
              );
            })}
          </StyledList>
          <Divider color={theme.palette.gray[600]} mb={1.25} mt={1.5} />
          <Slider />
        </AdvertisementContent>
      </AdvertisementWrapper>
      <Modal
        modalRef={{ ...modalRef, close: handleClose }}
        title={t("signup.modal.title")}
      >
        {t("signup.modal.description")}
        <br />
        <ModalConfirm onConfirm={handleClose} txtConfirm={"Try again"} />
      </Modal>
    </SignupGrid>
  );
};

export { BigScreenSignup };
