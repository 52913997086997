const CI_COMMIT_REF_NAME = "trunk";
const CI_COMMIT_SHA = "e0afe83000eb6f3eaa6fcff5479c9fa6feeceb01";
const CI_COMMIT_SHORT_SHA = "e0afe830";
const INTEGRATES_BUCKET_NAME = "integrates.front.production.fluidattacks.com";
const INTEGRATES_DEPLOYMENT_DATE = "2024-08-27T14:43:40Z";

export {
  CI_COMMIT_REF_NAME,
  CI_COMMIT_SHA,
  CI_COMMIT_SHORT_SHA,
  INTEGRATES_BUCKET_NAME,
  INTEGRATES_DEPLOYMENT_DATE,
};
