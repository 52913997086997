import { keyframes, styled } from "styled-components";

const clientsAnimation = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(calc(-188px * 47)); }
`;

const ClientsContainer = styled.div`
  overflow: hidden;
`;

const SlideShow = styled.div`
  display: flex;
  gap: 48px;
  animation: ${clientsAnimation} 150s linear infinite;
  width: calc(188px * 94);
`;

export { ClientsContainer, SlideShow };
