const CI_COMMIT_REF_NAME = "trunk";
const CI_COMMIT_SHA = "51888888172d6cecb342a26e455239534afdede5";
const CI_COMMIT_SHORT_SHA = "51888888";
const INTEGRATES_BUCKET_NAME = "integrates.front.production.fluidattacks.com";
const INTEGRATES_DEPLOYMENT_DATE = "2024-10-12T03:08:43Z";

export {
  CI_COMMIT_REF_NAME,
  CI_COMMIT_SHA,
  CI_COMMIT_SHORT_SHA,
  INTEGRATES_BUCKET_NAME,
  INTEGRATES_DEPLOYMENT_DATE,
};
