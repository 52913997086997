import { Link } from "react-router-dom";
import { styled } from "styled-components";

interface IStyledLinkProps {
  color?: string;
}

interface IAdvertisementWrapperProps {
  url: string;
}

const SignupGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
  gap: ${({ theme }): string => theme.spacing[2]};
  padding: ${({ theme }): string => theme.spacing[2]};

  @media (width <= 940px) {
    overflow-y: auto;
    grid-template-columns: 1fr;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledLink = styled(Link)<IStyledLinkProps>`
  color: ${({ color, theme }): string => color ?? theme.palette.gray[400]};
  text-decoration: underline;
`;

const AdvertisementWrapper = styled.div<IAdvertisementWrapperProps>`
  display: flex;
  padding: 0 73px;
  border-radius: ${({ theme }): string => theme.spacing[1.5]};
  background: url(${({ url }): string => url}) center center / cover no-repeat;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100vh;
  max-height: calc(100vh - 64px);
  min-height: 700px;
  min-width: 0;

  @media (width <= 1024px) {
    padding: 0 52px;
  }
`;

const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }): string => theme.spacing[0.75]};
  align-self: stretch;
  padding: 0;

  > li {
    display: flex;
    align-items: baseline;
    gap: ${({ theme }): string => theme.spacing[0.5]};
    align-self: stretch;
  }
`;

const AdvertisementContent = styled.div`
  width: 100%;
  max-width: 509px;
`;

export {
  AdvertisementWrapper,
  SignupGrid,
  FormWrapper,
  StyledLink,
  StyledList,
  AdvertisementContent,
};
